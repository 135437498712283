<script>
import Layout from "./../layouts/main";
import Swal from "sweetalert2";
import moment from "moment";

import addApiKey from "@/pages/api-key-form-modal";
import ExchangeConnection from "@/state/entities/exchangeConnection";

export default {
  data() {
    return {
      openModal: false,
    };
  },
  components: {
    Layout,
    addApiKey
  },
  methods: {
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    },
    confirm() {
      Swal.fire({
        title: this.$t("t-delete-confirm-question"),
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          Swal.fire(this.$t("t-delete-deleted"), "Your api key has been deleted.", "success");
        }
      });
    },
  },
  computed: {
    resultQuery() {
      return ExchangeConnection.all();
    },
  },
  async beforeMount() {
    (await ExchangeConnection.api().get('/exchange-connection'));
  },
};
</script>
<template>
  <Layout>
    <div class="row">
      <div class="col-xs-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center mb-4">
          <h5 class="card-title flex-grow-1 mb-0"></h5>
          <div class="live-preview">
            <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#add-api-key">
              <i class=" ri-add-circle-line me-1 align-bottom"></i>
              {{ $t("t-api-key-add-new") }}
            </button>
            <b-modal v-model="openModal"
                     hide-footer
                     id="add-api-key"
                     class="modal fade"
                     role="dialog"
                     :title='$t("t-api-key-add-new")'
                     >
              <add-api-key @created="openModal = false"></add-api-key>
            </b-modal>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-borderless align-middle mb-0">
                <thead class="table-light">
                <tr>
                  <th scope="col">{{ $t("t-api-key-name") }}</th>
                  <th scope="col">{{ $t("t-api-key-trading-platform") }}</th>
                  <th scope="col">{{ $t("t-api-key-single-key") }}</th>
                  <th scope="col">{{ $t("t-api-key-created-date") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data) of resultQuery" :key="data._id">
                  <td>{{ data.title }}</td>
                  <td>
                    <img v-if="data.type === 'bitget-futures'" src="@/assets/images/integrations/bitget.png" height="30">
                    <img v-if="data.type === 'bitget-spot'" src="@/assets/images/integrations/bitget.png" height="30">
                    <img v-if="data.type === 'binance-spot'" src="@/assets/images/integrations/binance.png" height="30">
                    <img v-if="data.type === 'binance-futures'" src="@/assets/images/integrations/binance.png" height="30">
                    <img v-if="data.type === 'bybit-spot'" src="@/assets/images/integrations/bybit.png" height="30">
                  </td>
                  <td>{{ data.api_key }}</td>
                  <td>{{ dateTime(data.created_at) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </Layout>
</template>